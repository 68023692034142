import "./App.css";
import "./sb-admin-2.min.css";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Userlist from "./pages/Userlist";
import Portal from "./pages/Portal";
import UserView from "./pages/UserView";
import UserEdit from "./pages/UserEdit";
import { useEffect } from "react";
import axios from "axios";
import Profile from "./pages/Profile";
import PrivateRoute from "./components/PrivateRoute";
import { useSelector } from "react-redux";

function App() {
  const state = useSelector((state) => state.auth);
  const userToken = localStorage.getItem("userToken");
  useEffect(() => {
    if (window.location.pathname === "/")
      window.location.pathname = "/dashboard";
  }, []);
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
  }, [userToken]);
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ${state?.userToken}`;
  }, [state.userToken, userToken]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Portal />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="user-list"
            element={
              <PrivateRoute>
                <Userlist />
              </PrivateRoute>
            }
          />
          <Route
            path="user-view/:id"
            element={
              <PrivateRoute>
                <UserView />
              </PrivateRoute>
            }
          />
          <Route
            path="user-edit/:id"
            element={
              <PrivateRoute>
                <UserEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
