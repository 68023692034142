import { useGetProfileData } from "../features/profile";
import React from "react";

const Profile = () => {
  const { isLoading, data } = useGetProfileData();
  return (
    <>
      <h1 className="text-center">My Profile</h1>
      {isLoading ? (
        <img src="https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif" />
      ) : (
        <div className="row d-flex mt-4">
          <div className="card col-sm-8 col-md-4 mr-md-4 p-4 mb-sm-4">
            <img
              src={data?.data?.imageUrl}
              className="w-25 m-auto"
              alt={data?.data?.firstName}
            />
            <div className="d-flex mt-2 justify-content-center">
              <h5 className="text-primary mx-1 font-weight-bold">
                First Name:
              </h5>
              <p>{data?.data?.firstName}</p>
            </div>
            <div className="d-flex mt-2 justify-content-center">
              <h5 className="text-primary mx-1 font-weight-bold">
                Last Name:{" "}
              </h5>
              <p>{data?.data?.lastName}</p>
            </div>
          </div>
          <div className="card col-sm-8 col-md-7 p-4">
            <div className="d-flex mt-2 justify-content-center">
              <h5 className="text-primary mx-1 font-weight-bold">
                First Name:
              </h5>
              <p>{data?.data?.firstName}</p>
            </div>
            <div className="d-flex mt-2 justify-content-center">
              <h5 className="text-primary mx-1 font-weight-bold">
                Last Name:{" "}
              </h5>
              <p>{data?.data?.lastName}</p>
            </div>
            <div className="d-flex mt-2 justify-content-center">
              <h5 className="text-primary mx-1 font-weight-bold">Email: </h5>
              <p>{data?.data?.email}</p>
            </div>
            <div className="d-flex mt-2 justify-content-center">
              <h5 className="text-primary mx-1 font-weight-bold">Role: </h5>
              <p>{data?.data?.role}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
