import React, { useState } from "react";

const RoleDropdown = ({ onSelectRole }) => {
  const [selectedRole, setSelectedRole] = useState("user"); // Default role

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    onSelectRole(e.target.value);
  };

  return (
    <div className="d-flex flex-row">
      <label htmlFor="role">Role:</label>
      <select
        id="role"
        value={selectedRole}
        onChange={handleRoleChange}
        className="form-control form-control-sm ml-2"
      >
        <option value="user">User</option>
        <option value="admin">Admin</option>
        {/* Add more options if needed */}
      </select>
    </div>
  );
};

export default RoleDropdown;
