import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userToken"),
  );
  const state = useSelector((state) => state.auth);
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userToken"));
  }, [localStorage.getItem("userToken"), state?.userToken]);
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children;
};
export default PrivateRoute;
