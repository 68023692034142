import axios from "axios";
import { useQuery } from "react-query";
import { baseUrl } from "./api";

const getUsers = async (
  role,
  page = { pageSize: "10", currentPage: "1" },
  searchName = { fistName: "", lastName: "", email: "" },
) => {
  const queryParameters =
    `role=${role}&perPage=${page.pageSize}` +
    `&firstName=${searchName.firstName}&lastName=${searchName.lastName}&email=${searchName.email}`;
  const { data } = await axios.get(
    `${baseUrl}/api/v1/user?${queryParameters}&page=${page.currentPage}`,
    {},
  );
  return data;
};

export const useGetUsers = (selectedRole, pageSize, searchName) => {
  return useQuery({
    queryKey: ["getUsers", selectedRole, pageSize, searchName],
    queryFn: () => getUsers(selectedRole, pageSize, searchName),
  });
};

const viewUser = async (id) => {
  const { data } = await axios.get(`${baseUrl}/api/v1/user/${id}`);
  return data.data;
};
export const useViewUser = (id) => {
  return useQuery({ queryKey: ["viewUser", id], queryFn: () => viewUser(id) });
};

export const useEditUser = async ({ id, formData }) => {
  const URL = `${baseUrl}/api/v1/user/${id}`;
  const response = await axios.put(URL, formData);
  return response.data;
};

export const useDeleteUser = async (id) => {
  const URL = `${baseUrl}/api/v1/user/${id}`;
  const response = await axios.delete(URL);
  return response.data;
};
