import {
  faFaceLaughWink,
  faTachographDigital,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {/* <!-- Sidebar - Brand --> */}
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/dashboard"
      >
        INearby Dashboard
      </Link>

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        <Link className="nav-link" to="dashboard">
          <FontAwesomeIcon
            icon={faTachographDigital}
            style={{ marginRight: "0.5rem" }}
          />
          <span>Dashboard</span>
        </Link>
      </li>
      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Users --> */}
      <li className="nav-item active">
        <Link className="nav-link" to="user-list">
          <FontAwesomeIcon icon={faUsers} style={{ marginRight: "0.5rem" }} />
          <span>Users</span>
        </Link>
      </li>
    </ul>
  );
}

export default Sidebar;
