import React, { useState } from "react";

const PaginationPageSize = ({ onChangePageSize, userList }) => {
  const [pageSize, setPageSize] = useState(10);

  const handleRoleChange = (e) => {
    setPageSize(e.target.value);
    onChangePageSize(e.target.value);
  };

  return (
    <div className="col-sm-12 col-md-6">
      <div className="w-25 mb-1">
        <label htmlFor="pageSize" className="d-inline text-center">
          Show{" "}
          <select
            className="form-control form-control-sm custom-select custom-select-sm d-inline"
            value={userList?.data?.pagingMeta?.perPage || pageSize}
            onChange={handleRoleChange}
            id="pageSize"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
          entries
        </label>
      </div>
    </div>
  );
};

export default PaginationPageSize;
