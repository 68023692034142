import ReactPaginate from "react-paginate";

const PaginationUsers = ({ userList, handleCurrentPage }) => {
  const handlePageClick = (data) => {
    handleCurrentPage(data.selected + 1);
  };
  return (
    <ReactPaginate
      previousLabel={"Previous"}
      nextLabel={"Next"}
      breakLabel={"..."}
      pageCount={userList?.data?.pagingMeta?.lastPage}
      marginPagesDisplayed={2}
      onPageChange={handlePageClick}
      containerClassName="pagination col-sm-12 col-md-6 justify-content-end"
      pageClassName="page-item"
      pageLinkClassName={"page-link"}
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      activeClassName="active"
      onPageActive={userList?.data?.pagingMeta?.currentPage}
    />
  );
};

export default PaginationUsers;
