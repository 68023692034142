import React, { useState } from "react";
import TableUsers from "../components/TableUsers";
import { useGetUsers } from "../features/users";
import RoleDropdown from "../components/RoleDropdown";
import PaginationPageSize from "../components/PaginationPageSize";
import PaginationUsers from "../components/PaginationUsers";

function Userlist() {
  const [selectedRole, setSelectedRole] = useState("user");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const {
    data: userList,
    isLoading,
    refetch,
  } = useGetUsers(
    selectedRole,
    {
      pageSize,
      currentPage,
    },
    {
      firstName,
      lastName,
      email,
    },
  );
  // console.log(userList);
  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };
  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
  };
  const handleCurrentPage = (currentPage) => {
    setCurrentPage(currentPage);
    console.log(currentPage);
  };
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "searchByFirstName":
        setFirstName(value);
        break;
      case "searchByLastName":
        setLastName(value);
        break;
      case "searchByEmail":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">User-List</h1>
      </div>
      <div className="card shadow mb-4 pb-2">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">DataTables</h6>
        </div>
        <div className="d-md-flex flex-row d-sm-block justify-content-between card-body">
          <RoleDropdown onSelectRole={handleRoleChange} />
          <div className="d-flex flex-row ml-2 mb-sm-1">
            <label htmlFor="searchByFirstName" className="w-50">
              First Name:
            </label>
            <input
              type="text"
              name="searchByFirstName"
              value={firstName}
              onChange={handleSearchChange}
              placeholder={"Search by first name"}
              className="form-control form-control-sm"
            />
          </div>
          <div className="d-flex flex-row ml-2">
            <label htmlFor="searchByLastName" className="w-50">
              Last Name:
            </label>
            <input
              id="searchByLastName"
              type="text"
              name="searchByLastName"
              value={lastName}
              onChange={handleSearchChange}
              placeholder={"Search by last name"}
              className="form-control form-control-sm"
            />
          </div>
          <div className="d-flex flex-row ml-2">
            <label htmlFor="searchByEmail" className="w-25">
              Email:
            </label>
            <input
              type="text"
              name="searchByEmail"
              value={email}
              onChange={handleSearchChange}
              placeholder={"Search by email"}
              className="form-control form-control-sm"
            />
          </div>
          {/*<div className="d-flex flex-row justify-content-between">*/}
          {/*  */}
          {/*</div>*/}
        </div>
        <div className="card-body">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif"
                className=" m-auto"
              />
            </div>
          ) : (
            <TableUsers
              userList={userList}
              refetchAllUsers={refetch}
              firstName={firstName}
              lastName={lastName}
              email={email}
              handleSearchChange={handleSearchChange}
            />
          )}
        </div>
        <div className="d-flex px-2 row">
          <PaginationPageSize
            userList={userList}
            onChangePageSize={handlePageSize}
          />
          <PaginationUsers
            userList={userList}
            handleCurrentPage={handleCurrentPage}
          />
        </div>
      </div>
    </>
  );
}

export default Userlist;
