import React, { useMemo, useState } from "react";
import { useTable } from "react-table";
import { Link } from "react-router-dom";
import DeleteUserModel from "./DeleteUserModel";
import { useDeleteUser } from "../features/users";
import { useMutation } from "react-query";

const TableUsers = ({ userList, refetchAllUsers }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { mutate: deleteUser } = useMutation(useDeleteUser, {
    onSuccess: () => {
      console.log("User deleted successfully");
      setShowConfirmModal(false);
      refetchAllUsers();
    },
    onError: (error) => {
      console.error("Error deleting user:", error);
      setShowConfirmModal(false);
    },
  });
  const handleDeleteUser = (userId) => {
    console.log("Deleting user with ID:", userId);
    deleteUser(userId);
    setShowConfirmModal(false);
  };
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            <Link
              to={`/user-view/${row.original.userId}`}
              className="btn btn-primary btn-sm mr-1"
            >
              View
            </Link>
            <Link
              to={`/user-edit/${row.original.userId}`}
              className="btn btn-info btn-sm mr-1"
            >
              Edit
            </Link>
            <button
              className="btn btn-danger btn-sm mr-1"
              onClick={() => {
                setSelectedUserId(row.original.userId);
                setShowConfirmModal(true);
              }}
            >
              Delete
            </button>
            <DeleteUserModel
              isOpen={
                showConfirmModal && selectedUserId === row.original.userId
              }
              selectedUserId={selectedUserId}
              onClose={() => setShowConfirmModal(false)}
              onDelete={() => handleDeleteUser(selectedUserId)}
            />
          </div>
        ),
      },
    ],
    [selectedUserId, showConfirmModal],
  );
  const data = useMemo(() => userList?.data?.users, [userList]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <div className="table-responsive">
      <table
        {...getTableProps()}
        className="table table-bordered"
        id="dataTable"
        width="100%"
        cellSpacing="0"
      >
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          {rows?.length === 0 && (
            <tr>
              <td colSpan="5" className="text-center">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default TableUsers;
