import React from "react";
import { Link, useParams } from "react-router-dom";
import { useViewUser } from "../features/users";

function UserView() {
  const params = useParams();
  const { id } = params;
  const { data: userList, isLoading } = useViewUser(id);

  return (
    <>
      <div>UserDetails</div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">UserDetails</h6>
        </div>
        <div className="card-body">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif"
                className=" m-auto"
              />
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={userList.imageUrl}
                  alt={`${userList.firstName} Img`}
                  className="img-profile img-fluid w-25"
                />
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-6 d-flex">
                  <h5 className="text-primary mx-1 font-weight-bold">
                    First Name:
                  </h5>
                  <p>{userList.firstName}</p>
                </div>
                <div className="col-12 col-md-6 d-flex">
                  <h5 className="text-primary mx-1 font-weight-bold">
                    Last Name:{" "}
                  </h5>
                  <p>{userList.lastName}</p>
                </div>
                <div className="col-12 col-md-6 d-flex">
                  <h5 className="text-primary mx-1 font-weight-bold">
                    Email:{" "}
                  </h5>
                  <p>{userList.email}</p>
                </div>
                <div className="col-12 col-md-6 d-flex">
                  <h5 className="text-primary mx-1 font-weight-bold">
                    Address:{" "}
                  </h5>
                  <p>
                    {userList.address
                      ? `${userList.address}`
                      : "No address available"}
                  </p>
                </div>
                <div className="col-12 col-md-6 d-flex">
                  <h5 className="text-primary mx-1 font-weight-bold">Role: </h5>
                  <p>{userList.role}</p>
                </div>
                <div className="col-12 col-md-6 d-flex">
                  <h5 className="text-primary mx-1 font-weight-bold">
                    Balance:{" "}
                  </h5>
                  <p>{userList.balance}</p>
                </div>
                <div className="d-flex mx-3">
                  <Link to={`/user-edit/${id}`} className="btn btn-primary">
                    Edit
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UserView;
