import React from "react";
import { useGetProfileData } from "../features/profile";

function Dashboard() {
  const { data, isLoading } = useGetProfileData();

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center">
      <img
        src="https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif"
        className=" m-auto"
      />
    </div>
  ) : (
    <h1 className="d-flex justify-content-center align-items-center text-primary font-weight-bold">
      {" "}
      Welcome {data?.data?.firstName} to Dashboard
    </h1>
  );
}

export default Dashboard;
