import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEditUser, useViewUser } from "../features/users";
import { useMutation } from "react-query";

function UserEdit() {
  const params = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const { data, isLoading } = useViewUser(params.id);
  useEffect(() => {
    if (data) {
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setAddress(data.address === null ? "no" : data.address);
    }
  }, [data]);

  const mutation = useMutation(
    useEditUser, // Assuming useEditUser is a function that performs the edit user mutation
    {
      onSuccess: (data) => {
        console.log("User updated:", data);
        navigate("/user-view/" + params.id);
        // You can perform further actions here on successful mutation
      },
      onError: (error) => {
        console.error("Error updating user:", error);
        // Handle error, e.g., show an error message
      },
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form...");
    const userData = {
      firstName,
      lastName,
      email,
      address,
    };
    mutation.mutate({ id: params.id, formData: userData });
  };

  return (
    <>
      <h3>UserEdit </h3>
      {mutation.isError && (
        <div>An error occurred: {mutation.error.message}</div>
      )}
      {mutation.isLoading && <div>Updating user...</div>}
      {mutation.isSuccess && (
        <div className="alert alert-success">User updated successfully!</div>
      )}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <img
            src="https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif"
            className=" m-auto"
          />
        </div>
      ) : (
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <label>First Name</label>
                <input
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-lg-6">
                <label>Last Name</label>
                <input
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-lg-6">
                <label>E-Mail</label>
                <input
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="form-control"
                />
              </div>
              <div className="col-lg-6">
                <label>Address</label>
                <input
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <input
                  disabled={isLoading}
                  type="submit"
                  value={isLoading ? "Updating..." : "Update"}
                  className="btn btn-primary"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default UserEdit;
