import React from "react";
import Modal from "react-modal";

// Modal component styles
const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "400px",
    height: "170px",
    margin: "auto",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
  },
};

const DeleteUserModel = ({ isOpen, onClose, onDelete, selectedUserId }) => {
  const handleConfirmDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      ariaHideApp={false} // Prevents a11y warnings
    >
      <h2>Confirm Delete</h2>
      <p>Are you sure you want to delete this user?</p>
      <div style={{ textAlign: "right" }}>
        <button
          onClick={onClose}
          style={{ marginRight: "10px" }}
          className="btn btn-light btn-sm mr-1"
        >
          Cancel
        </button>
        <button
          onClick={handleConfirmDelete}
          className="btn btn-danger btn-sm mr-1"
        >
          Confirm Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteUserModel;
