import { baseUrl } from "./api";
import axios from "axios";
import { useQuery } from "react-query";

const getProfileData = async () => {
  const { data } = await axios.get(`${baseUrl}/api/v1/user/me`, {});
  return data;
};

export const useGetProfileData = () => {
  return useQuery({
    queryKey: ["getProfileData"],
    queryFn: getProfileData,
  });
};
