import React from "react";
import { Link } from "react-router-dom";
import { useGetProfileData } from "../features/profile";
import { Dropdown } from "react-bootstrap";

function Topbar() {
  const profileData = useGetProfileData();
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow d-flex justify-content-end">
      <Dropdown>
        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
          {profileData.data?.data?.firstName}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Link to="/profile" className="dropdown-item">
            Profile
          </Link>
          <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </nav>
  );
}

export default Topbar;
